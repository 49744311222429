import { getTranslations } from 'i18n/i18n';

import {
  QUICK_DATE_FROM_TODAY,
  QUICK_DATE_FROM_TOMORROW,
  QUICK_DATE_ON_WEEKEND,
} from 'src/constants/feed';
import { FLAG_REASON_ABUSIVE, FLAG_REASON_OTHER, FLAG_REASON_SPAM } from 'src/constants/flag';
import {
  POST_CREATION_CAMPAIGN_PLATFORM_FACEBOOK,
  POST_CREATION_CAMPAIGN_PLATFORM_GOOGLE,
  POST_CREATION_CAMPAIGN_PLATFORM_INSTAGRAM,
  POST_CREATION_CAMPAIGN_PLATFORM_LOKALPORTAL,
  POST_CREATION_CAMPAIGN_PLATFORM_NW_DIGITAL,
  POST_CREATION_CAMPAIGN_PLATFORM_NW_PRINT,
} from 'src/constants/post_creation';
import {
  ADMIN_PROFILE_TYPE,
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  INTERMEDIATE_PROFILE_TYPE,
  NO_USER_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  PRIVATE_PROFILE_TYPE,
  REPORTER_PROFILE_TYPE,
} from 'src/constants/profile';
import {
  DECODED_KEY_ADDRESS,
  DECODED_KEY_CATEGORIES,
  DECODED_KEY_FIRST_NAME,
  DECODED_KEY_LAST_NAME,
  DECODED_KEY_NAME,
} from 'src/constants/profile_creation';
import {
  SETTINGS_ERROR_CODE_ALREADY_CONFIRMED,
  SETTINGS_ERROR_CODE_ALREADY_TAKEN,
  SETTINGS_ERROR_CODE_AREA_NOT_RELEASED,
  SETTINGS_ERROR_CODE_EMAIL_ALREADY_IN_USE,
  SETTINGS_ERROR_CODE_INVALID_GOOGLE_PLACE_ID,
  SETTINGS_ERROR_CODE_INVALID_INTENT,
  SETTINGS_ERROR_CODE_INVALID_TOKEN,
  SETTINGS_ERROR_CODE_MISSING_EMAIL,
  SETTINGS_ERROR_CODE_TOKEN_EXPIRED,
  SETTINGS_ERROR_CODE_UNPROCESSABLE_DATA,
  SETTINGS_ERROR_CODE_USER_ALREADY_REGISTERED,
  SETTINGS_ERROR_CODE_USER_DENIED_AUTHORIZATION,
  SETTINGS_ERROR_CODE_USER_NOT_REGISTERED,
} from 'src/constants/settings';
import { bookmarkableTypes, commentableTypes } from 'src/interfaces/notification';

export const textResources = getTranslations();

const Translate = {
  authorType: {
    [ADMIN_PROFILE_TYPE]: 'Admin',
    [AUTHORITY_PROFILE_TYPE]: textResources.profile.authorityProfile,
    [BLOG_PROFILE_TYPE]: 'Partner & Sponsoren',
    [INTERMEDIATE_PROFILE_TYPE]: 'Unbekannt',
    [NO_USER_PROFILE_TYPE]: 'Unbekannt',
    [PRESS_PROFILE_TYPE]: 'Dein Verein',
    [PRIVATE_PROFILE_TYPE]: 'Community',
    [REPORTER_PROFILE_TYPE]: 'Reporter',
  },

  authorTypeCategoryTitle: {
    [ADMIN_PROFILE_TYPE]: 'Admin',
    [AUTHORITY_PROFILE_TYPE]: 'Partner & Sponsoren',
    [BLOG_PROFILE_TYPE]: 'Partner & Sponsoren',
    [INTERMEDIATE_PROFILE_TYPE]: 'Dein Verein',
    [NO_USER_PROFILE_TYPE]: 'Dein Verein',
    [PRESS_PROFILE_TYPE]: 'Dein Verein',
    [PRIVATE_PROFILE_TYPE]: 'Community',
    [REPORTER_PROFILE_TYPE]: 'Seiten',
  },

  createFlagReasons: {
    [FLAG_REASON_ABUSIVE]: 'Beleidigung',
    [FLAG_REASON_OTHER]: 'Sonstiges',
    [FLAG_REASON_SPAM]: 'Spam',
  },

  createProfileDecodedKey: {
    [DECODED_KEY_ADDRESS]: 'Adresse',
    [DECODED_KEY_CATEGORIES]: 'Kategorien',
    [DECODED_KEY_FIRST_NAME]: 'Vorname',
    [DECODED_KEY_LAST_NAME]: 'Nachname',
    [DECODED_KEY_NAME]: 'Name',
  },

  datePickerQuickDate: {
    [QUICK_DATE_FROM_TODAY]: 'Heute',
    [QUICK_DATE_FROM_TOMORROW]: 'Morgen',
    [QUICK_DATE_ON_WEEKEND]: 'Am Wochenende',
  },

  notifications: {
    bookmarkBookmarkableType: {
      [bookmarkableTypes.authority_profile]: 'dein Behördenprofil',
      [bookmarkableTypes.blog_profile]: 'dein Sponsor',
      [bookmarkableTypes.comment]: 'deinen Kommentar',
      [bookmarkableTypes.event]: 'deine Veranstaltung',
      [bookmarkableTypes.post]: 'deinen Beitrag',
      [bookmarkableTypes.press_profile]: 'deine Abteilung',
      [bookmarkableTypes.reporter_profile]: 'dein Reporterprofil',
    },
    commentCommentableType: {
      [commentableTypes.event]: 'die Veranstaltung',
      [commentableTypes.post]: 'den Beitrag',
    },
  },

  postCreateAddressValidationError: {
    address_not_found: 'Bitte gib die vollständige Adresse mit Straße und Ort ein!',
    area_not_released: 'Der eingegebene Ort ist noch nicht freigeschaltet. Bitte gib einen anderen Ort ein!',
    default: 'Der eingegebene Ort ist ungültig. Bitte gib einen anderen Ort ein!',
    invalid_parameters: 'Ups... da ist was schief gegangen. Bitte versuche es erneut, oder gib einen anderen Ort ein!',
    out_of_reach: 'Der eingegebene Ort liegt außerhalb des erlaubten Bereichs. Bitte gib einen anderen Ort ein!',
  },

  postCreateCampaignPlatform: {
    [POST_CREATION_CAMPAIGN_PLATFORM_FACEBOOK]: 'Facebook',
    [POST_CREATION_CAMPAIGN_PLATFORM_GOOGLE]: 'Google',
    [POST_CREATION_CAMPAIGN_PLATFORM_INSTAGRAM]: 'Instagram',
    [POST_CREATION_CAMPAIGN_PLATFORM_LOKALPORTAL]: 'Lokalportal',
    [POST_CREATION_CAMPAIGN_PLATFORM_NW_DIGITAL]: 'Neue Westfälische (digital)',
    [POST_CREATION_CAMPAIGN_PLATFORM_NW_PRINT]: 'Neue Westfälische (print)',
  },

  settingsPageError: {
    [SETTINGS_ERROR_CODE_ALREADY_CONFIRMED]: 'Deine E-Mail-Adresse wurde bereits bestätigt.',
    [SETTINGS_ERROR_CODE_ALREADY_TAKEN]: 'Diese E-Mail-Adress ist bereits vergeben.',
    [SETTINGS_ERROR_CODE_AREA_NOT_RELEASED]: 'Dieser Ort ist leider noch nicht freigeschaltet.',
    [SETTINGS_ERROR_CODE_EMAIL_ALREADY_IN_USE]: 'Diese E-Mail-Adresse wird bereits verwendet.',
    [SETTINGS_ERROR_CODE_INVALID_GOOGLE_PLACE_ID]: 'Ups... da ist was schiefgelaufen.',
    [SETTINGS_ERROR_CODE_INVALID_INTENT]: 'Ups... da ist was schiefgelaufen.',
    [SETTINGS_ERROR_CODE_INVALID_TOKEN]: 'Der Link ist leider ungültig.',
    [SETTINGS_ERROR_CODE_MISSING_EMAIL]: 'Du hast noch keine E-Mail-Adresse angegeben.',
    [SETTINGS_ERROR_CODE_TOKEN_EXPIRED]: 'Der Link ist leider abgelaufen.',
    [SETTINGS_ERROR_CODE_UNPROCESSABLE_DATA]: 'Die Daten können nicht verarbeitet werden.',
    [SETTINGS_ERROR_CODE_USER_ALREADY_REGISTERED]: 'Ups... da ist was schiefgelaufen.',
    [SETTINGS_ERROR_CODE_USER_DENIED_AUTHORIZATION]: 'Du hast die Authorisierung verweigert.',
    [SETTINGS_ERROR_CODE_USER_NOT_REGISTERED]: 'Bitte registriere dich erst.',
  },
};

export default Translate;
